.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: pixelated; /* Chrome as of 2019   */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: stretch 1.5s ease-out 0s alternate infinite none running;
  }
}

.App-header {
  background-color: #222034;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
